<template>
  <div class="warp"
       style="font-size:12px;"
       v-loading="Loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="tab tab-top">
      <div class="tab-item toptab" :class="{active:index==0}" @click="MenuActive(0)">充值</div>
      <div class="tab-item toptab" :class="{active:index==1}" @click="MenuActive(1)">订单</div>
    </div>
    <div class="main">
      <div class="page page-1 active">
        <el-row :gutter="12">
          <el-col :span="12">
            <div class="liebox uidbox">
              <div class="alpha"></div>
              <span class="title"
                    style="width:180px;margin-left:-90px;font-size:14px;"><i class="fa fa-user"></i>分区信息</span>
              <div style="font-size:14px;margin-top:20px;padding:0px 10px;color:rgb(147 147 147);">
                <p style="margin-bottom:0px;">游戏名称：<span style="color:#d20000;">{{info.name}}</span></p>
                <p style="margin-bottom:0px;">兑换比例: <span style="color:#d20000;">1RMB：{{info.column}}</span>
                  <span v-if="info.currency == 1">点卷</span>
                  <span v-else-if="info.currency == 2">代币</span>
                  <span v-else-if="info.currency == 3">金币</span>
                </p>
                <p style="margin-bottom:0px;">客服联系: <span style="color:#d20000;">{{info.QQ}}</span></p>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="liebox uidbox">
              <div class="alpha"></div>
              <span class="title"
                    style="width:180px;margin-left:-90px;font-size:14px;"><i class="fa fa-yen"></i>奖励赠送</span>
              <div style="height:104px;overflow:auto;font-size:12px;margin-top:20px;padding:0px 10px;color:rgb(93 92 56);">
                <template v-if="info.giving == 1">
                  <p v-for="(v, i) in info.giving_reward"
                     :key="i"
                     style="margin-bottom:0px;">
                    <span>满【{{v.price}}】元额外返【{{v.return}}】元(点卷)</span>
                  </p>
                </template>
                <template v-else>
                  <p style="padding:26px 82px;">暂无数据</p>
                </template>
                <!-- <p style="padding:26px 82px;">暂无数据</p> -->
              </div>
            </div>
          </el-col>

          <el-col :span="24">
            <div style="margin-top:20px;padding: 0px 10px;color:#99941d;">
              <div style="text-align: center;">
                <h4>充值信息</h4>
              </div>
              <div style="padding:2px 0px;font-size:12px;">
                <el-row :gutter="12">
                  <el-col :span="12">
                    <p style="margin:10px 0px;"><span>充值账号：</span><input type="text"
                             v-model="form.user"
                             class="service__input"></p>
                    <p v-if="info.currency == 3"
                       style="margin:10px 0px;"><span>充值角色：</span>
                      <select v-model="form.role"
                              class="service__input">
                        <option value="volvo">Volvo</option>
                        <option value="saab">Saab</option>
                        <option value="opel">Opel</option>
                        <option value="audi">Audi</option>
                      </select>
                      <span style="color:#280909;background:rgb(255 82 0);padding:1px 2px;position:relative;left:4px;border-radius:2px;cursor:pointer"
                            @click="getRole()">获取</span>
                    </p>
                    <p style="margin:10px 0px;"><span>充值金额：</span><input type="number" min="1" max="99999999999" v-model="form.money" style="width:131px;" class="service__input"></p>
                  </el-col>
                  <el-col :span="12">
                    <p v-if="info.currency == 3 || info.check == 1"
                       style="margin:10px 0px;"><span>账户密码：</span><input type="password"
                             v-model="form.pwd"
                             class="service__input"></p>
                    <p style="margin:10px 0px;"><span>联系方式：</span><input type="text"
                             v-model="form.contact"
                             class="service__input"></p>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-col>
          <el-col :span="24">
            <div style="margin-top:10px;padding: 0px 10px;color:#99941d;">
              <div style="padding:2px 0px;font-size:12px;">
                <p style="margin:10px 0px;margin-left:10px;"><span>充值方式：</span>
                  <el-radio-group v-model="form.pay"
                                  size="mini">
                    <el-radio v-for="(v, i) in info.pay_data"
                              :key="i"
                              :label="v.id"
                              border>{{v.name}}</el-radio>
                  </el-radio-group>
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="col-sm-12"
             style="text-align:center;">
          <button class="btn-default"
                  lay-submit
                  lay-filter="submit"
                  style="position:absolute;left:18px;top:414px;"
                  @click="payOrder">去充值</button>
        </div>
      </div>

      <!-- 订单 -->
      <div class="page page-2">
        <div class="row"
             style="margin-right:0px;margin-left:0px;">
          <div class="col-sm-12"
               style="margin-top:12px;color:#adb80c;font-size: 12px;padding:0px 22px;">
            <p style="margin-bottom:0px">温馨提示：</p>
            <p style="margin-bottom:0px">1、订单支付后可能有延迟10-20秒到账和请留意查看充值账号是否正确（否则请联系商家处理）</p>
            <p style="margin-bottom:0px">2、联系客服QQ：{{info.QQ}} </p>
          </div>
          <div class="col-sm-12"
               style="margin-top:4px;text-align:center;">
            <p style="padding:22px 0px;">
              <input v-model="order_form.keywords"
                     placeholder="请输入充值账户/订单号/联系方式查询..."
                     class="service__input"
                     style="height: 36px;width: 410px;border:1px solid #678267;background:#c5b48e;"><span @click="search"
                    style="cursor:pointer;padding:11px 14px;background:#0d0a8c;border-radius:2px;color: aliceblue;"> 搜索</span>
            </p>
          </div>
          <div class="col-sm-12"
               style="margin-top:14px;padding-right:12px;padding-left:12px;">
            <div style="height:262px;overflow:auto;background: rgb(28 27 27);">
              <div v-if="order_form.data.length <= 0"
                   style="text-align:center;padding:120px 0px;color:#616161;">
                暂无订单
              </div>
              <el-row v-else
                      :gutter="12">
                <el-col :span="24"
                        v-for="(v, i) in order_form.data"
                        :key="i"
                        class="order_lis">
                  <el-row :gutter="12">
                    <el-col :span="14">
                      <div style="display:inline-block;">
                        <p style="margin-bottom:4px">订单号码:{{v.order}}</p>
                        <p style="margin-bottom:4px">充值账号:{{v.username}}</p>
                        <p style="margin-bottom:4px">付款状态:
                          <span v-if="v.state == 0"
                                style="color:#f00;">待付款</span>
                          <span v-if="v.state == 1">已支付</span>
                        </p>
                        <p style="margin-bottom:0px">订单时间:{{v.time}}</p>
                      </div>
                    </el-col>
                    <el-col :span="10">
                      <div style="display:inline-block;">
                        <p style="margin-bottom:4px">订单区服:{{v.gateway_name}}</p>
                        <p style="margin-bottom:4px">订单金额:{{v.money}}</p>
                        <p style="margin-bottom:4px">到账状态:
                          <span v-if="v.avaState == 0"
                                style="color:#f00;">未到账</span>
                          <span v-if="v.avaState == 1">已到账</span>
                        </p>
                        <p style="margin-bottom:0px">支付方式:{{v.pay_name}}</p>

                      </div>
                    </el-col>
                    <el-col :span="24">
                      <div class="col-sm-12"
                           style="text-align:center;margin-top:16px;">
                        <span style="background:#525252;padding:2px 10px;color:#c5b48e;border-radius:2px;"
                              @click="PopMessage(false,'联系客服QQ:'+info.QQ)">联系商家</span>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alert">
      <div class="alert_bg"
           :style="MessageDisplay">
        <div class="alert_main">
          <div class="alert_title"><span>提示</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <span>{{ MessageMsg }}</span>
              <div class="btn"
                   @click="CloseAlert">关闭</div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>

      <div class="alert_bg"
           :style="SuccessDisplay">
        <div class="alert_main">
          <div class="alert_title"><span>提示</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <span>{{ MessageMsg }}</span>
              <div class="btn"
                   @click="SuccessAlert">关闭</div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>

      <div class="alert_bg"
           :style="PayDisplay">
        <div class="alert_main"
             style="margin-top:5%;width: 528px;">
          <div class="alert_title"><span>提示</span></div>
          <div class="alert_con">
            <div class="alert_com"
                 style="background: #fff;">
              <el-row>
                <el-col :span="11">
                  <div style="text-align:left;margin-left:10px;color:#b80c0c;">
                    <p style="margin-bottom:6px;">订单：<span style="display:inline-block;padding:0px; 0px;">{{pay_info.order}}</span></p>
                    <p style="margin-bottom:6px;">金额：<span style="display:inline-block;padding:0px; 0px;">{{pay_info.amount}}</span></p>
                    <p style="margin-bottom:6px;">付款：<span style="display:inline-block;padding:0px; 0px;">{{pay_info.pay_name}}</span></p>
                    <p style="margin-bottom:6px;">账户：<span style="display:inline-block;padding:0px; 0px;">{{pay_info.username}}</span></p>
                    <p style="margin-bottom:6px;">货币：<span style="display:inline-block;padding:0px; 0px;">{{pay_info.ava}}</span></p>
                    <p style="margin-bottom:6px;">日期：<span style="display:inline-block;padding:0px; 0px;">{{pay_info.time}}</span></p>
                    <p style="margin-top:10px;margin-bottom:0px;color:#b80c0c;">因为窗口大小有限，部分支付接口无法完整显示付款码，请手动通过左右，上下滑动展示完整的付款码即可</p>
                  </div>
                </el-col>
                <el-col :span="13">
                  <div style="text-align: center">
                    <iframe v-if="pay_info.pay_type == 0"
                            frameborder="0"
                            scrolling="auto"
                            :src="pay_info.url"
                            style="width: 286px; height: 334px"></iframe>
                            <el-image v-else-if="pay_info.pay_type == 1"
                            style="width: 200px; height: 200px"
                            :src="pay_info.url"></el-image>
                  </div>
                </el-col>
              </el-row>
              <p style="margin-top:10px;margin-bottom:0px;color:#b80c0c;">扫码完成，请不要点关闭，如20秒内不弹出结果，再关闭请手动查询订单</p>
              <div style="text-align:center;padding:10px 0px">
                <div class="btn"
                     @click="PayDisplayAlert"
                     style="margin:0px 0px;display:inline-block;">取消</div>
              </div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
  <script>
import $ from "jquery";
import Vue from "vue";
export default {
  name: "Gateway",
  data () {
    return {
      MessageDisplay: "display:none", //弹出框显示
      SuccessDisplay: "display:none", //成功弹出框
      PayDisplay: "display:none", //支付
      MessageMsg: "", //弹出框消息
      Loading: false,
      index:0,
      info: {
        name: '',
        column: 1,//'充值比例'
        currency: 1,//'充值联系' 1点卷 2代币 3金币
        QQ: '', //联系QQ
        giving: 0, //批量开关
        giving_reward: [], //批量优惠
        amount: 1, //最低充值金额
        check: 0,//充值检验账户密码
        pay_data: [], //支付接口
      },
      form: {
        user: '',
        user2: '',
        role: '',
        money: '10',
        pwd: '',
        contact: '',
        pay: '',
      },
      order_form: {
        keywords: '',
        data: [],
      },
      pay_info: {
        url: '',
        name: '',
        order: '',
        amount: '0',
        pay_name: '',
        username: '',
        ava: 0,
        time: '',
        pay_type: 0,
      },
      role_data: [],
      timing: null,
    };
  },
  created () {
    this.getInfo();
  },
  methods: {
    //获取基本配置
    getInfo () {
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/gateway/getInfo",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.info = response.data.info;
            this.form.money = response.data.info.amount;
            if (response.data.info.pay_data.length > 0) {
              this.form.pay = response.data.info.pay_data[0].id;
            }
          } else {
            this.PopMessage(true, response.data.msg);
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    //获取角色
    getRole () {
      if (this.Loading == true) {
        return false;
      }
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/getRole",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          username: this.form.user,
          password: this.form.pwd,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.role_data = response.data.info;
            this.PopMessage(false, response.data.msg)

          } else {
            this.PopMessage(false, response.data.msg)
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    //下单
    payOrder () {
      if (this.Loading == true) {
        return false;
      }
      clearInterval(this.timing);
      this.Loading = true;
      this.form.key = this.$route.query.key
      this.form.keyid = this.$route.query.id
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/gateway/payOrder",
        // 传递参数
        data: this.form,
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.pay_info = response.data.info;
            this.PayDisplay = "display:block";
            this.timing = setInterval(() => {
              this.GetTimingOrder(response.data.info.order)
            }, 3000)
          } else {
            this.PopMessage(false, response.data.msg)
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });

    },
    //定时监测订单
    GetTimingOrder (order) {
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/gateway/getTimingOrder",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          order: order,
        },
        responseType: "json",
      })
        .then((response) => {
          if (response.data.code == 200) {
            this.PayDisplayAlert()
            this.PopMessage(false, "充值成功")
          }
        })
        .catch((error) => {
          // 请求失败，
          console.log(error);
        });
    },
    search () {
      if (this.Loading == true) {
        return false;
      }
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/gateway/getOrder",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          keywords: this.order_form.keywords,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.order_form.data = response.data.info;
          } else {
            this.PopMessage(false, response.data.msg)
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    //弹出框状态
    PopMessage (type, msg) {
      this.SuccessDisplay = "display:none";
      this.MessageDisplay = "display:none";
      this.MessageMsg = msg;
      if (type == false) {
        this.MessageDisplay = "display:block";
      } else {
        this.SuccessDisplay = "display:block";
      }
    },
    //关闭窗口
    CloseAlert () {
      this.SuccessDisplay = "display:none";
      this.MessageDisplay = "display:none";
    },
    SuccessAlert () {
      this.SuccessDisplay = "display:none";
      this.MessageDisplay = "display:none";
      this.getInfo();
    },
    //关闭支付页面
    PayDisplayAlert () {
      clearInterval(this.timing);
      this.PayDisplay = "display:none";
    },
    MenuActive(index){
      this.index = index;
      $('.page').removeClass('active');
      $('.toptab').removeClass('active');
      $(this).addClass('active');
      $('.page').eq(index).addClass('active');
    },
  },
};
</script>

  <style src="../../../public/static/plug/gateway/game/static/css/game.css" scoped>
</style>
<style src="../../../public/static/plug/gateway/game/static/css/body.css" scoped>
</style>
<style lang="scss" scoped>
.main {
  /deep/ .el-row {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
}
</style>
  <style>
#root,
body,
html {
  min-width: 0px;
}
</style>
  